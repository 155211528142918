import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './App.css';


const App = () => {

  // const images = [
  //   'https://shorturl.at/nsv45',
  //   'https://edunewsnetwork.files.wordpress.com/2021/07/green-solution.jpg',
  // ];
  
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    className: 'notes-slider',
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
      <div className="App">    
        <div className="container">
          
            <header className="App-header">
              <h1>Romarin Design</h1>
              <p>Elegance Redefined: Discover Timeless Jewelry Designs That Capture Your Unique Style</p>
            </header>

          <Slider {...settings}>
            <div>
              <img alt="img1" src="https://i.etsystatic.com/46310354/r/il/b882ce/5599885644/il_1588xN.5599885644_98a6.jpg" />
            </div>
            <div>
              <img alt="img2" src="https://i.etsystatic.com/46310354/r/il/f06d95/5594314266/il_1588xN.5594314266_fh9q.jpg" />
            </div>
            <div>
              <img alt="img3" src="https://i.etsystatic.com/46310354/r/il/913acf/5222041284/il_1588xN.5222041284_8wy9.jpg" />
            </div>
            <div>
              <img alt="img4" src="https://i.etsystatic.com/46310354/r/il/786bfe/5601591543/il_1588xN.5601591543_mmhd.jpg" />
            </div>
            <div>
              <img alt="img4" src="https://i.etsystatic.com/46310354/r/il/2d9d48/5601556537/il_1588xN.5601556537_kswc.jpg" />
            </div>  
            <div>
              <img alt="img4" src="https://i.etsystatic.com/46310354/r/il/9a32b4/5573721874/il_1588xN.5573721874_t1yq.jpg" />
            </div>
            <div>
              <img alt="img4" src="https://i.etsystatic.com/46310354/r/il/ae4c6a/5610276661/il_1588xN.5610276661_2vtt.jpg" />
            </div>            
            <div>
              <img alt="img4" src="https://i.etsystatic.com/46310354/r/il/f06d95/5594314266/il_1588xN.5594314266_fh9q.jpg" />
            </div>            
          </Slider>  
        </div>  
    </div>
  );
}

export default App;
